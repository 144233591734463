
<!-- 
  As of 6th June 2024, we're not using this file, all functionality has been moved to the main default layout and this isn't needed. This coincided with the move to the sidedrawer layout.
 -->

<template>
  <div class="">
    <SeoKit />
    <TheTopNav class="mx-auto" />
    <main class="w-full min-h-screen primary-content flex flex-col relative overflow-hidden">
      <div class="w-full m-auto" style="min-height:70vh;z-index: 1;">
        <NuxtPage :transition="false"/>
      </div>
      <!-- <div class="gradient-bg" style="z-index:0;width:120%;left:-10%;top:0"></div> -->
      
      <TheFooter class="mt-auto mx-auto" v-if="!subDomain"/>

      <div class="flex justify-center items-center py-24" v-else>
        <NuxtLink to="https://aihairstyles.com" class="logo-dark hidden text-center" target="_blank">
          <span class="text-xs muted">Powered by</span>
          <img src="@/assets/img/logo-wordmark-dark.svg"  alt="Logo" width="125">
          <p class="muted text-xs p-3">
            v 
            {{ metadata.buildMajor }}.
            {{ metadata.buildMinor }}.
            {{ metadata.buildRevision }}         
          </p>
        </NuxtLink>
        <NuxtLink to="https://aihairstyles.com" class="logo-light hidden text-center" target="_blank">
          <span class="text-xs muted">Powered by</span>
          <img src="@/assets/img/logo-wordmark-light.svg"  alt="Logo" width="125">
          <p class="muted text-xs p-3">
            v 
            {{ metadata.buildMajor }}.
            {{ metadata.buildMinor }}.
            {{ metadata.buildRevision }}         
          </p>
        </NuxtLink>
      </div>
    </main>

  </div>
</template>

<script setup>
import metadata from '../appVersion.json'

const subDomain = useSubdomain()

</script>